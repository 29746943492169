<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import dealerForm from './form/detail.vue';

export default {
  name: 'DaShang_budget_record_list',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    dealerForm,
  },
  methods: {
    modalClick({ val, row }) {
      console.log(val.code);
      if (val.code === 'detail') {
        this.formName = 'dealerForm';
        this.modalConfig.title = '明细';
        this.modalConfig.width = '80%';
        this.formConfig.row = row;
        this.formConfig.code = val.code;
        this.openDrawer();
      }
    },
  },
  created() {
    this.getConfigList('DaShang_budget_record_list');
  },
};
</script>
